
.slide-out-task {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 28%;
  right: -1px;
  padding: 10px 60px;
  width: 320px;
  height: 55%;
  min-height: 490px;
  cursor: pointer;
  transition: right 1.5s;
  text-decoration: none;
  list-style: none;
  margin-right: 10px;
  box-shadow: 4px 4px 5px #3054a1;
  border: 1px solid #3054a1;
  background-color: rgb(231, 231, 231);
}

  .slide-out-task > li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    /* overflow-x: scroll; */
  }

  .hidden-task {
    right: -700px;
  }

  .cushion {
    margin-top: 100px;
  }

  .slide-out-task-item {
    font-size: 26px;
  }

  .edit-task-button-confirms{
    width: 100%;
  }
