.footer {
    /* position: absolute;
    bottom: 0; */
    width: 100%;
    height: 40px;
    background-color: var(--jackblack);
    color: white;
    font-family: "Helvetica Neue", sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 10pt;
    margin-bottom: 0;
    z-index: 1;
}

/* .social-links {
    display: flex;
    flex-direction: column;
}

.social-links a {
    color: white;
    text-decoration: none;
    background-color: black;
}
*/

.about-asante {
    word-break: break-word;
    /* width: 300px; */

    background-color: var(--jackblack);
}

.social-links a {
    color: white;
    text-decoration: none;
    background-color: var(--jackblack);
}

.social-links a:hover{
    text-decoration: underline;
}

.fa-solid {
    font-size: 10px;
    margin: 0 5px 0 5px;
}

.fa-github, .fa-linkedin {
    margin: 10px;
    font-size: 20px;
}
