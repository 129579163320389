.homepage-container {
  font-family: 'Noto Sans', sans-serif;
  display: grid;
  place-items: center;
  padding: 50px;
}
.board-title-home{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.yellow {
  display: grid;
  place-items: center stretch;
  width: 1000px;
  margin: 50px 0px;
}

.red {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blue {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 65px;
  margin-top: 25px;
}


.hello-user h2 {
    font-size: 30px;
}

.current-date {
    font-size: 18px;
    color: grey;
}

#homepage-task-link,
#homepage-board-link {
    text-decoration: none;
    color: var(--jackblack);
    border: 1px solid var(--jackblack);
    border-radius: 5px;
    box-shadow: 1px 1px 5px var(--jackblack);
    padding: 5px 5px;
}

#homepage-tasks-container,
#homepage-boards-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 1fr;
}

/* .homepage-user-tasks,
.homepage-user-boards-container {
  display: grid;
  gap: 10px;
  margin-bottom: 5px;
} */

.homepage-user-task-tile,
.homepage-user-board-tile {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--jackblack);
  padding-bottom: 5px;
}

#homepage-task-link,
#homepage-board-link {
  cursor: pointer;
  border: var(--jackblack);
}

#homepage-tasks-container,
#homepage-boards-container {
  border: solid 1px darkgrey;
  border-radius: 10px;
  box-shadow: 1px 1px 10px darkgrey;
  /* border-left: white; */
  padding: 5px 20px;
  /* background-color: rgb(219, 218, 218); */
}

#homepage-board-link,
#homepage-task-link {
    background-color: white;
    color: var(--jackblack);
}

#homepage-boards-container h2,
#homepage-tasks-container h2 {
    /* color: var(--jackblack); */
    /* color: var(--jackblack); */
    margin-bottom: 0;
}

.hello-user h2 {
  /* color: rgb(255, 157, 0); */
  color: var(--jackblack);
}

.task-grid-home{
  margin: 0 auto;
  max-width: 1fr;
  min-width: 1fr;
  /* margin-left: 400px; */
}

.task-item-home {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
  gap: 30px;
  text-align: center;
}

.task-items-home {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
  gap: 30px;
}

.task-items-home > p {
  width: 130px;
}
