/* body {
    font-family: "Helvetica Neue", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

} */

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;
  /* height: 100vh; */
}

.home-nav-container {
  font-family: "Helvetica Neue", sans-serif;
  /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 70px;
  width: 100%;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-left a {
  padding-top: 5px;
  text-decoration: none;
  margin-left: 17px;
  /* margin-right: 10px; */
  color: grey;
  cursor: not-allowed;
}

.navbar-right a {
  padding-top: 5px;
  text-decoration: none;
  margin-left: 17px;
  margin-right: 10px;
  color: grey;

}

.navbar-left a:hover{
  text-decoration: underline;
}

.navbar-right a:hover{
  text-decoration: underline;
}

/** keeps the navlinks grey even after you clicked on it**/
.navbar-left a:visited {
  color: grey;
}

.navbar-right a:visited {
  color: grey;
}

/***************** MAIN SECTION ******************/

.main-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Helvetica Neue", sans-serif;
  /* height: 30vh; */
  margin: auto;
  width: 100%;
  gap: 70px;
}

.main-left {
  font-family: 'Quicksand', sans-serif;
}

.main-left p {
  margin-bottom: 50px;
}

.main-right {
  /* border: 5px solid rgb(192, 243, 255); */
  margin: 0px;
  padding: 0 45px;
}

/********* BOTTOM SECTION ******/
.main2-section-section {
  display: flex;
  /* align-items: center; */
  /* justify-content: space-around; */
  /* margin-top: 20px; */
  padding-top: 50px;
  font-family: "Helvetica Neue", sans-serif;
  margin: auto;
  width: 100%;
}

.main2-section-left{
  margin-left: 100px;
}

.main2-section-left-text {
  display: flex;
  flex-direction: column;
  /* gap: 6px; */
}

.main2-section-left-text h2 {
  margin: 9px;
  margin-left: 0px;
}
.main2-section-container p {
  font-size: 20px;
  /* margin: 0 10px 5px 0px; */
}

/********* CAPITAL 'DRIVE EFFICIENCY ACROSS TEAMS' *******/
.uppercase-text {
  text-transform: uppercase;
  font-size: 16px;
  color: darkgray;
}

/********* List View / Board View layout for title and bio **********/
.layouts-styling div {
  font-size: 20px;
  margin-bottom: 7px;
  /* width: 35%; */
  margin-top: 30px;
}

.layouts-styling p {
  font-size: 16px;
  color: gray;
  margin-bottom: 22px;
  font-family: 'Quicksand', sans-serif;
  /* width: 35%; */
}

/******************* BUTTONS *********************/
.get-started-btn-1 {
  margin-left: 20px;
  background-color: var(--asantered);
  color: white;
  border: var(--jackblack) 1px solid;
  font-size: 17px;
  padding: 3px 15px 3px 15px;
  cursor: pointer;
  border-radius: 5px;
}

.get-started-btn-2 {
  font-size: 17px;
  margin-right: 17px;
  background-color: var(--asantered);
  border: var(--jackblack) 1px solid;
  color: white;
  font-size: 17px;
  padding: 3px 15px 3px 15px;
  cursor: pointer;
  border-radius: 5px;
}

.get-started-btn-1:hover{
  background-color: #e92626;
}

.get-started-btn-2:hover{
  background-color: #e92626;
}

.demo-user-btn {
  font-size: 17px;
  background-color: var(--jackblack);
  color: white;
  font-size: 17px;
  padding: 3px 15px 3px 15px;
  cursor: pointer;
  border-radius: 5px;
}

.demo-user-btn:hover{
  background-color: rgb(68, 66, 66);
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.button1 {
  border: none;
  border-radius: 10px;
  padding: 0 16px;
  transition: background-color 0.6s;
  font-size: 17px;
  cursor: pointer;
}

.button1:hover {
  background-color: #f47878;
  color: white;
}

.button2 {
  border: none;
  border-radius: 10px;
  padding: 0 16px;
  font-size: 17px;
  cursor: pointer;
  transition: background-color 0.6s;
}

.button2:hover {
  background-color: #f4c579;
  color: white;
}
.button3 {
  border: none;
  border-radius: 10px;
  padding: 0 16px;
  font-size: 17px;
  cursor: pointer;
}

.button3:hover {
  background-color: #acf2ee;
  color: white;
}

.button4 {
  border: none;
  border-radius: 10px;
  padding: 0 16px;
  transition: background-color 0.6s;
  font-size: 17px;
  cursor: pointer;
}

.button4:hover {
  background-color: #9f98e9;
  color: white;
}

.button5 {
  border: none;
  border-radius: 10px;
  padding: 0 16px;
  transition: background-color 0.6s;
  font-size: 17px;
  cursor: pointer;
}

.button5:hover {
  background-color: #6eb394;
  color: white;
}

.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page {
  width: 1200px;
  min-width: 950px;
  max-width: 1200px;
}

.icon-nav{
  display: flex;
  align-items: center;
}

.site-logo-landing{
  color: var(--jackblack);
}

.section-selection{
  margin-top: 20px;
  border: 1px solid var(--jackblack);
  border-radius: 5px;
  padding: 15px;
  max-width: 750px;
}

.section-selection div {
  font-size: 20px;
}

.section-selection p {
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  color: gray;
}

.main2-section-left-text > p, div{
  font-family: 'Quicksand', sans-serif;
}

.border-divider-cls{
  border-bottom: 1px solid var(--jackblack);
  width: 20%;
  margin: 0;
}

.border-divider2-cls{
  border-bottom: 1px solid var(--jackblack);
  width: 20%;
  margin: 0;
}

.main-left > h1 {
  margin-bottom: 20px;
}

.main-left > p {
  margin-top: 20px;
}
