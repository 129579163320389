.single-board-border {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1000px;
    padding: 80px;
}

.board-container {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 1000px;
    min-width: 800px;

}

.warning-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.board-and-trash {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.board-and-trash > h2 {
    margin: 0;
}


#board-trash {
    /* align-self: flex-end; */
    /* margin-right: 42px; */
    font-size: 28px;
}

#xmark {
    font-size: 22px;
}

#check {
    font-size: 22px;
    margin-right: 40px;
    color: red;
}

.delete-warning-board {
    display: flex;
    align-items: center;
    height: 50px;
    margin-left: 20px;
}

.check-and-x {
    display: flex;
    justify-content: center;
    margin-left: 5px;
}

.delete-text {
    color: red;
}
.delete-warning {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* align-self: flex-end; */
    /* height: 60px; */
}

.fa-solid {
    cursor: pointer;
}

.aligned-right {
    align-self: flex-end;
    margin-right: 46px;
}
