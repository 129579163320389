.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  background-color: #1a1919;
  max-height: 49px;
  position: fixed;
  width: 100%;
}

#site-icon{
  width: 36px;
  height: 36px;
}

#site-logo{
  color: white;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
}

.home-left-navbar{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.profile-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 57px;
  right: -16px;
  border: 1px solid black;
  background-color: var(--jackblack);
  color: white;
  padding: 5px;
  width: fit-content;
  transition: right 1s;
  text-decoration: none;
  border-bottom-left-radius: 20px;
}

.profile-dropdown > li {
  list-style:  none;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  overscroll-behavior: none;
  height: 25px;
}

.profile-dropdown > .hoverable:hover {
  list-style:  none;
  background-color: rgb(214, 214, 214, .3);
  cursor: pointer;
  width: 100%;
}

.profile-link{
  text-decoration: none;
  color: white;
}

.hidden {
  /* display: none; */
  overflow: hidden;
  overscroll-behavior: none;
  right: -300px;
}

.nav-list {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  padding: 15px 0;
  margin-right: 16px;
  position: sticky;
  top: 0px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
}

.nav-list > li {
  list-style:  none;
}

.nav-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  /*
  background-color: black;
  color: white;
  font-size: 17px;
  padding: 3px 15px 3px 15px;
  min-width: 60px;
  min-height: 20px;
  cursor: pointer;
  box-shadow: 3px 2px 3px grey;
  transition: box-shadow, background-color .6s; */
}

/* .nav-list-item:hover {
  box-shadow: 4px 4px 4px grey;
  background-color: white;
  color: black;
}

.nav-list-item:active {
  box-shadow: 1px 1px 1px grey;
} */
