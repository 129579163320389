.single-section-border {
    border: 1px solid black;
    border-radius: 20px;
    padding: 6px;
    padding-top: 30px;
    /* min-height: 200px; */
    margin: 20px;
    background-color: white;
    min-width: 300px;
    max-width: 300px;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    min-height: 400px;
    max-height: 800px;
    height: 560px;
    transition: box-shadow .2s ease;
}

.single-section-border::-webkit-scrollbar {
    display: none;
}

.single-section-border:hover {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.432);
}

.single-section-border:active {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.432);
}

.section-header {
    display: flex;
    justify-content: center;;
    font-size: 22px;
    font-weight: bold;
    text-align: center;

}

.section-gallery {
    border: 1px solid rgba(65, 64, 64, 0.596);
    border-radius: 10px;
    background-color: rgba(231, 231, 231, 0.801);
    box-shadow: 1px 1px 3px var(--jackblack);
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: 900px;
    max-width: 1500px;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    min-height: 400px;
    max-height: 690px;
    height: 640px;
}

.section-gallery::-webkit-scrollbar {
    display: none;
  }

#trash {
    font-size: 22px;
}

.kb-or-mouse {
    display: flex;
    justify-content: center;
    align-items: center;
}

#keyboard {
    font-size: 28px;
    cursor: default;
}

#mouse {
    font-size: 28px;
    cursor: default;
}

.scroll-container {
    display: flex;
    justify-content: center;
}

#left-right {
    font-size: 28px;
    align-self: center;
    margin-left: 10px;
    cursor: default;
}

.tooltip-container {
    display: flex;
    /* justify-content: flex-end; */
}

#tooltip {
    cursor: pointer;
    font-size: 22px;
    margin-bottom: 2px;
    margin-right: 5px;
}

