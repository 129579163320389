.single-task-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid black; */
    padding: 20px;
    max-width: 220px;
}

.single-task-desc{
    width: 100%;
    word-wrap: break-word;
    text-align: center;
}

.single-task-card:hover{
    background-color: #da363673;
    border-radius: 6px;
}

.single-task-card > h3, h4 {
    margin: 2px 0px;
}

.board-task {
    display: flex;
    justify-content: center;

    /* margin-right: 40px; */
    /* border: 1px solid pink; */
    /* margin-right: 40px; */
}

.board-hidden-task {
    display: none;
}

.form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

#task-overdue{
    color: var(--asantered);
}

.edit-task-input-single {
    width: 100%;
    /* width: 100px; */
}

.single-error-container {
    min-height: 12px;
    font-size: 16px;
    color: red;
    font-weight: bold;
}


.description-input{
    resize: none;
}
