.form {
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-task-input {
    width: 100%;
}

.updated {
    color: green;
    font-weight: bold;
    height: 24px;
}

