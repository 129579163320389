.create-board-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: -9px;
    left: 225px;
    padding: 5px;
    padding-right: 10px;
    width: 250px;
    cursor: pointer;
    transition: all 1s;
    text-decoration: none;
    z-index: 1;
    background-color: #1a1919;
    height: 195px;
    border-bottom-right-radius: 20px;
}

.create-board-slide-small{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 49px;
    left: 0px;
    padding: 5px;
    padding-right: 10px;
    width: 250px;
    cursor: pointer;
    transition: all 1s;
    text-decoration: none;
    z-index: 1;
    background-color: #1a1919;
    height: 195px;
    border-bottom-right-radius: 20px;
}


.create-board-slide > div {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.submit-create {
    height: 28px;
    outline: none;
    background-color: transparent;
    border: none;
}

.submit-create > i {
    font-size: 22px;
}

.new-section-form > input{
    outline: none;
    border: none;
}

#new-section-input{
    border: none;
}

.create-board-input{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    height: 26px;
}

.create-board-input > input {
    width: 100%;
    border: none;
    box-shadow: inset 0px 0px 0px 0px white;
    outline: none;
}

.create-board-input > button {
    box-shadow: inset 0px 0px 0px 0px white;
    outline: none;
    /* border: none; */
    min-height: 26px;
    cursor: pointer;

}

.edit-section-div{
    margin-bottom: 10px;
}

.createBoardHidden {
    top: -300px;
}

.new-section-form{
    display: flex;
}

.create-board-btn{
    height: 40px;
    width: 120px;
    border: 1px rgb(117, 117, 117) solid;
    border-radius: 30px;
    color: white;
    background-color: #1a1919;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 16px;
}
.create-board-btn > i {
    font-size: 22px;
    margin-right: 15px;
}

.create-board-btn-small{
    height: 25px;
    width: 100px;
    border: 1px rgb(117, 117, 117) solid;
    border-radius: 30px;
    color: white;
    background-color: #1a1919;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 14px;
}

.create-board-btn-small > i {
    font-size: 16px;
    margin-right: 8px;
}

.create-button-div > button:hover{
    background-color: rgb(151, 151, 151,.3);
    cursor: pointer;
}


.create-button-div-small > button:hover{
    background-color: rgba(46, 46, 46, 0.986);
    cursor: pointer;
}

.create-board-text{
    color: white;
}

.create-board-text-small{
    color: white;
    padding-bottom: 15px;
    text-align: center;
}

.create-board-div{
    display: flex;
    flex-direction: column;
    height: 195px;
    /* border-left: 1px rgb(117, 117, 117) solid; */
}

.create-board-div > form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.edit-section-div{
    border: 2px solid var(--jackblack);
    border-radius: 5px;
}
