.mobile-banner-container {
    margin-top: 0;
    padding: 80px;
    font-size: 34px;
    position: absolute;
    height: 200vh;
    width: 120vh;
    z-index: 100;
    background-color: whitesmoke;
    text-align: center;
    display: none;
    flex-wrap: wrap;
}

.mobile-banner {
    position: absolute;
    width: 24%;
    z-index: 3;
}
.mobile-img {
    position: absolute;
    width: 24%;
    z-index: 2;
}


@media screen and (max-width: 912px) {
    .mobile-banner-container {
        display: flex;
    }
    .mobile-banner {
        width: 14%;
    }
    .mobile-img {
        width: 14%;
    }
}

@media screen and (max-width: 650px) {
    .mobile-banner-container {
        display: flex;
        padding-left: 10px;
    }
    .mobile-banner {
        width: 26%;
    }
    .mobile-img {
        width: 26%;
    }
}

@media screen and (max-width: 500px) {
    .mobile-banner-container {
        padding-left: 0px;
    }
    .mobile-banner {
        padding-left: 0;
    }
    .mobile-banner {
        width: 30%;
    }
    .mobile-img {
        width: 30%;
    }
}
