.task-item {
    display: grid;
    grid-template-columns: 2fr 1fr 130px;
    padding: 10px;
    border: 1px solid lightgrey;
}

.task-items {
    display: grid;
    grid-template-columns: 2fr 1fr 130px;
    padding: 10px;
    border: 1px solid lightgrey;
}

.task-grid {
    margin: 0 auto;
    max-width: 1000px;
    min-width: 800px;
    /* margin-left: 400px; */
    box-shadow: 2px 2px 16px grey;
}

.my-tasks-page{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

#my-tasks-boardname{
    width: fit-content;
}

#my-tasks-getboard{
    width: fit-content;
}

.task-item  > p {
    font-size: 16px;
    font-weight: 700;
}
