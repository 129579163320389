.app{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 100%; */
    min-height: 100vh;
}

footer{
    font-family: 'Noto Sans', sans-serif;
}

body {
    margin: 0;
    overflow-x: hidden;
    /* overflow-y: scroll; */
    /* max-width: 100vp; */
    /* position: relative; */
    min-height: 100%;

    display: flex;
    flex-flow: column wrap;
    align-items: space-between;
    font-family: 'Quicksand', sans-serif;
}

#root {
    overflow-x: hidden;
    --jackblack: #1a1919;
    --asantered: #da3636;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
}

/* #root > div {
    min-height: 1000px;
} */

.overflow-hide {
    overflow-x: hidden;
    border: 1px solid red;
}
.change-cursor{
    cursor: pointer;
}

.profile-page{
    padding: 50px;
}

.buffer-space{
    height: 200px;
}

.buffer-space-big{
    height: 382px;
}
