.task-gallery {
    min-height: 400px;
    /* border: 1px solid red !important; */
    margin: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.tasks-container {
    /* border: 1px dotted black; */
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.single-task-border {
    background-color: white;
    border: 1px solid black;
    align-items: center;
    border-radius: 10px;
    transition: box-shadow .2s ease;
}

.single-task-border:hover {
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.432);
}

.single-task-border:active {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.432);
}

#pen {
    font-size: 22px;
}

#plus {
    font-size: 22px;
    /* align-self: flex-end; */
}

#minus {
    font-size: 22px;
}

.add-task-sec-butn{
    display: flex;
    cursor: pointer;
}

.edit-section-btn{
    display: flex;
    cursor: pointer;
}

.delete-section-btn{
    display: flex;
    cursor: pointer;
}

.border-divider-tasks{
    border-bottom: 1px solid var(--jackblack);
    margin-top: 10px;
    margin-bottom: 0;
}

.close-section-creation{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    border: 2px solid var(--jackblack);
    border-radius: 5px;
}

.delete-warning-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.delete-text-section {
    color: red;
}

#section-plus {
    border: 1px solid var(--jackblack);
    background-color: rgba(231, 231, 231, 0.664);
    border-radius: 5px;
    padding: 15px;
    align-self: center;
    font-size: 18px;
    color: var(--asantered);
    font-weight: 700;
}


#section-minus {
    padding-bottom: 5px;
    font-size: 18px;
    color: var(--asantered);
}

.section-check-x {
    margin-left: 16px;
}

#section-xmark {
    font-size: 22px;
}
#section-check {
    font-size: 22px;
    color: red;
}

#section-trash {
    font-size: 22px;
}
